@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: europa, Open Sans, Segoe UI, sans-serif;
}

.bg-pattern {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3.81 19.62'%3E%3Cpath fill='%2328e8dd' d='M0 19.41l3.4-8.06a.15.15 0 01.19-.09.18.18 0 01.1.2L.28 19.53a.12.12 0 01-.13.09H.09a.15.15 0 01-.08-.2M3.72 8.36h-.06a.12.12 0 01-.13-.09L.11.2a.17.17 0 01.1-.2.15.15 0 01.19.1l3.39 8.06a.15.15 0 01-.07.2'/%3E%3C/svg%3E ");
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: auto 90px;
}

input.outline:focus-within ~ label,
input.outline:not(:placeholder-shown) ~ label {
    @apply transform -translate-y-4 z-0 px-1 py-0 text-xs;
}
